module.exports = {
  siteTitle: "hbendev blog", // <title>
  shortSiteTitle: "web development blog", // <title> ending for posts and pages
  siteDescription:
    "Blog about programming, web development, both backend and frontend, about technologies like JavaScript, React, Svelte, GraphQl, Apollo, Vue and TypeScript.",
  siteUrl: "https://hben.dev",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Bendegúz Hajnal",
  authorTwitterAccount: "h_bende",

  /* info */
  headerTitle: "Bendegúz Hajnal",
  headerSubTitle: "web development blog",

  /* manifest.json */
  manifestName: "Bendegúz Hajnal's web developemnt blog",
  manifestShortName: "hbendev blog", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/005cc7c42f351bd26e61a2f5846b17ca",
  gravatarImgMd5: "https://www.gravatar.com/avatar/005cc7c42f351bd26e61a2f5846b17ca",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/hajnalbendeguz" },
    { name: "twitter", url: "https://twitter.com/h_bende" }
  ]
};
